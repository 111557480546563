// =========================================================
// 
//  Flexible Services List
// 
// =========================================================

@import "assets/scss/utilities/mixins";
@import "assets/scss/utilities/variables";
@import "../../../node_modules/foundation-sites/scss/util/_unit.scss";
@import "../../../node_modules/foundation-sites/scss/util/value.scss";
@import "../../../node_modules/foundation-sites/scss/util/_breakpoint.scss";

.flexible-services-list {
    background: repeat-x bottom left;

    // Default padding and margin if not set under 'Settings'.
    
    &.padding-default {
        padding-top: 0;
        padding-bottom: 5rem;

        @include breakpoint(xlarge) {
            padding-bottom: 10rem;
        }
    } 
    
    &.small-padding-default {
        @include breakpoint( small only ) {
                
        }
    }  
    
    &.margin-default {
        margin-top: 0;
        margin-bottom: 0;
    } 
    
    &.small-margin-default {
        @include breakpoint( small only ) {
               
        }
    }  
}

.services-list {

    &__left-column {
        position: relative;
    }

    &__right-column {
        position: relative;

        @include breakpoint( small only ) {
            margin-top: rem-calc(32px);   
        }
    }

    &__right-column > &__container {
        @include breakpoint(xlarge) {
            padding-left: 2rem;
        }
    }

    &__header {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: rem-calc(48px);
        line-height: (59/48);
        color: #424142;
        margin-bottom: 1rem;

        @include breakpoint( small only ) {
            font-size: rem-calc(32);   
        }
    }

    &__text {
        margin-bottom: 1rem;
        color: var(--black);
        p { 
            color: inherit; 
            font-size: rem-calc(18px);
            line-height: rem-calc(30px);
        }
    }

    &__link {
        margin-top: 2rem;

        .button {
            @include breakpoint( small only ) {
                margin-bottom: 0;   
            }
        }
    }

    // Services Cards
    
    &__group {
        max-width: rem-calc(600px);
        width: 100%;
    }

    .slick-slide.slick-active[class] {
        .bfg-card:nth-child(2) {
            transform: translateX(95px);
        }

        .bfg-card:only-child {
            transform: translateX(95px);
        }
    }

    .slick-slide[class] {
        @include breakpoint( small only ) {
            margin-top: 0;
            margin-bottom: 0;   
        }
    }

    .slick-arrow {
        @include breakpoint( small only ) {
            display: none !important;  
        }
    }

    .slick-dots {
        @include breakpoint( small only ) {
            bottom: -33px;   
        }
    }

    .bfg-card {
        padding-top: 1rem;
        padding-bottom: 1rem;
        transform: 2s 0.4s transform;
        
        @include breakpoint(large) {
            height: rem-calc(220px);
        }
        
    }

}








